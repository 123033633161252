/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
}

.richtext {
    color: $c-black;

    h1 {
        @include page-title;
        
        margin-bottom: 20px;
        color: $c-black;
    }

    h2 {
        @include large-title;

        margin-bottom: 20px;
        color: $c-black;
    }

    h3 {
        @include medium-title;

        color: $c-black;
        margin-bottom: 20px;
    }

    h4 {
        @include small-title--alt;

        margin-bottom: 20px;
    }

    h5 {
        @include subtitle;

        margin-bottom: 10px;
    }

    h6 {
        @include tiny-title;
    }

    p {
        @include breakpoint(tablet) {
            word-break: break-word;
        }

        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    p + h1,
    p + h2,
    p + h3,
    p + h4 {
        margin-top: 50px;

        @include breakpoint(tablet) {
            margin-top: 40px;
        }
    }

    p + h5 {
        margin-top: 20px;
    }

    p, h1, h2, h3, h4, h5, h6, p, span {
        scroll-margin-top: 116px;

        @include breakpoint(nav) {
            scroll-margin-top: 108px;
        }
    }

    a {
        color: $c-black;
        text-decoration: underline;
    }

    strong {
        @include mediumText;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: 1em;
    }

    ul ul,
    ol ol {
        margin-top: .5em;
    }

    ul {
        list-style: none;
        padding-left: 15px;
        position: relative;

        li:before {
            content: "";
            position: absolute;
            left: -15px;
            top: 10px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background-color: $c-black;
            border-radius: 50%;
        }
    }

    ol {
        list-style: decimal outside;
        padding-left: 20px;
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    li {
        margin: 0;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    ul li p {
        margin: 0;
    }

    ul li ,
    ol li {
        padding-left: 3px;
    }

    dl {
        margin: 0 2em 1em 2.5em;
    }

    dt {
        font-weight: bold;
        margin: 1em 0 0.4em 0;

    }

    dd {
        line-height: 1.3em;
        margin-bottom: 0.5em;
    }

    hr {
        border: 0;
        height: 1px;
        background-color: $c-border-grey;
        margin: 1.875em 0;
    }

    blockquote {
        margin: 0 0 1.875em;

        p:last-child {
            margin-bottom: 0;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
    }

        tr {
            border-bottom: 1px solid $c-border-grey;

            &:last-child {
                border-bottom: none;
            }
        }

            th, td {
                padding: 10px 5px 5px;
                @include font(15px, 24px);
            }

            th {
                @include subtitle;
                width: 30%;
            }

            td {
                width: 70%;
            }
}

.richtext--inherit {
    color: inherit;
}

.richtext--white {
    color: $c-white;
}

.richtext--large {
    @include intro;

    p {
        @include intro;
    }
}
