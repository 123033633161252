.generic-form {
    padding: 65px 50px;

    @include breakpoint(tablet) {
        padding: 30px 20px;
    }
}

.generic-form--center {
    text-align: center;
}

    .generic-form__subtitle {
        @include subtitle;

        margin-bottom: 10px;
        text-align: center;

        @include breakpoint(phablet) {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .generic-form__title {
        @include large-title;

        margin-bottom: 20px;
        text-align: center;
    }

    .generic-form__intro {
        @include intro;

        margin-bottom: 50px;
        text-align: center;

        @include breakpoint(tablet) {
            margin-bottom: 25px;
        }
    }

    .generic-form__image {
        margin-bottom: 20px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .generic-form__text {
        margin-bottom: 20px;
    }

    .generic-form__form {
        padding: 50px 65px 0;
        border-top: 1px solid $c-border-grey;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 20px;
        row-gap: 25px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            padding: 25px 0 0;
            row-gap: 15px;
        }

        .field {
            margin: 0;
        }

        .intl-tel-input {
            width: 100%;
        }
    }

        .generic-form__address-lookup {
            grid-column: span 2;
            display: flex;
            gap: 20px;
            align-items: flex-end;

            @include breakpoint(tablet) {
                grid-column: span 1;
                flex-wrap: wrap;
            }
        }

        .generic-form__optin {
            grid-column: span 2;
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;

            @include breakpoint(tablet) {
                grid-column: span 1;
            }
        }

            .generic-form__optin-label {
                flex-basis: 100%;
                margin-bottom: 10px;
            }

        .generic-form__cta {
            grid-column: span 2;
            display: flex;
            justify-content: center;

            @include breakpoint(tablet) {
                grid-column: span 1;
            }

            @include breakpoint(phablet) {
                flex-direction: column;
            }
        }

.generic-form__field {

}

    .generic-form__field--title,
    .generic-form__field--subject {
        grid-column: span 2;
        max-width: calc(50% - 10px);

        @include breakpoint(tablet) {
            grid-column: span 1;
            max-width: none;
        }
    }

    .generic-form__field--address-lookup,
    .generic-form__field--message,
    .generic-form__field--optins {
        grid-column: span 2;

        @include breakpoint(tablet) {
            grid-column: span 1;
        }
    }

    .generic-form__field--telephone .iti {
        width: 100%;
    }

    .generic-form__field--address-lookup .field {
        width: 100%;
    }
