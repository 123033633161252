.sub-header {
    display: none;
    background-color: $c-white;
    padding: 20px;
    padding-right: 30px;
    position: absolute;
    top: calc(100% + 5px); // allow for border
    left: 0;
    width: 700px;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, .11);

    .header__item--active & {
        display: grid;

        @include breakpoint(nav) {
            width: 100%;
            top: 51px;
            height: calc(100dvh - var(--header-height-mobile) - var(--alert-bar-height) - 38px);
            padding: 30px 0 40px;
            z-index: 1;
            overflow-y: scroll;
            z-index: -1;
            grid-template-rows: auto 1fr;
            box-shadow: none;
        }
    }
}

.sub-header--large {
    grid-template-columns: 1fr 30%;
    align-items: flex-start;
    width: calc(100vw - (2 * var(--header-padding)));
    left: var(--header-padding);
    top: 100%;

    @include breakpoint(nav) {
        grid-template-columns: 1fr;
        width: 100%;
        top: 0;
        left: 0;
    }
}

    .sub-header__title {
        @include subtitle;

        .sub-header--large & {
            grid-column: span 2;

            @include breakpoint(nav) {
                grid-column: span 1;
            }
        }
    }

    .sub-header__list {
        column-count: 2;
        column-gap: 50px;
        margin-top: 15px;
        column-rule: 1px solid var(--theme-colour);
        padding-left: 25px;
        border-left: 1px solid var(--theme-colour);

        @include breakpoint(nav) {
            padding: 0;
            column-count: 1;
            display: flex;
            border-left: none;
            flex-direction: column;
            gap: 10px;
        }

        li:last-child {
            padding-top: 12px;
        }

        .header__item--cruiseline & {
            li:last-child {
                padding-top: 0;
            }
        }

        .sub-header--large & {
            column-count: 3;

            @include breakpoint(nav) {
                column-count: 1;
            }
        }
    }

        .sub-header__link {
            @include apply-map($f-title);
            @include font(20px, 28px);

            display: block;
            color: $c-black;
            text-decoration: none;
            padding: 4px 0;

            &:hover {
                cursor: pointer;

                span {
                    border-bottom: 1px solid $c-black;
                }
            }
        }
