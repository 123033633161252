.feefo-footer {
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $c-feefo-grey;
    color: $c-white;
    gap: 10px;

    @include breakpoint(tablet) {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, auto));
        gap: 5px;
        padding: 15px 10px;
        height: auto;
        padding-bottom: 100px;
    }

    span {
        @include font(18px, 18px);
        @include semiBoldText;

        @include breakpoint(tablet) {
            @include font(14px, 14px);
        }
    }

    p {
        @include breakpoint(tablet) {
            @include font(12px, 12px);
        }
    }

    .has-brochure & {
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

    img.feefo-footer__stars {
        width: 100px;
        height: auto;
        margin-top: 3px;

        @include breakpoint(tablet) {
            width: 70px;
        }
    }

    img.feefo-footer__logo {
        width: 96px;
        height: auto;
        margin-left: 5px;
        margin-bottom: 6px;

        @include breakpoint(tablet) {
            width: 80px;
            margin: 0;
            grid-row: 2 / 3;
            grid-column: 1 / 4;
            justify-self: center;
        }
    }
