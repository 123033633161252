:root {
    --header-height: 80px;
    --alert-bar-height: 40px;
    --header-height-mobile: 62px;
    --site-max-width: 1600px;
    --site-tab-max-width: 720px;
    --site-min-width: 300px;
    --site-gutter: 40px;
    --site-gutter-mobile: 20px;
    --narrow-width: 1220px;
    --very-narrow-width: 880px;
    --breadcrumb-height: 71px;
    --brochure-height: 80px;
    --theme-colour: #{$c-purple};
}

.adventures,
.adventures-page {
    --theme-colour: #{$c-blue};
    --notice-colour: #{$c-blue};
    --button-colour: #{$c-blue};
}

