.footer-circles {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    gap: 70px;
    padding: 0 50px 65px;
    border-bottom: 1px solid $c-border-grey;
    margin-bottom: 35px;

    @include breakpoint(tablet) {
        flex-wrap: wrap;
        gap: 20px;  
        grid-row: 4 / 5;
        grid-column: span 1;
        margin-top: 35px;
        padding: 0 0 50px;
        margin-bottom: 30px;
    }
}

    .footer-circles__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-decoration: none;

        @include breakpoint(tablet) {
            gap: 5px;
            flex-basis: calc(50% - 10px);
        }
    }

        .footer-circles__image {
            aspect-ratio: 1 / 1;
            overflow: hidden;
            border-radius: 50%;
            width: 90%;

            @include breakpoint(tablet) {
                max-width: 105px;
                width: 100%;
                max-height: 105px;
                margin-bottom: 5px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                transition: transform 500ms ease-in;

                .footer-circles__item:hover & {
                    transform: scale(1.075);
                }
            }
        }

        .footer-circles__title {
            @include small-title;

            text-align: center;
            color: var(--theme-colour);
        }
