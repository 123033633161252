.footer {
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 460px minmax(0, 1fr);
    column-gap: 100px;
    margin: 70px 0 35px;

    @include breakpoint(nav) {
        column-gap: 0;
    }

    @include breakpoint(tablet) {
        margin: 50px 0 0;
        grid-template-columns: 1fr;
    }
}

    .footer__logo {
        @include breakpoint(tablet) {
            display: flex;
            justify-content: center;

            svg {
                width: 224px;
                height: 96px;
            }
        }

        svg {
            width: 212px;
            height: 91px;
        }
    }
    
    .footer__newsletter {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include breakpoint(tablet) {
            grid-row: 3 / 4;
        }
    }

    .footer__nav {
        @include breakpoint(tablet) {
            grid-row: 5 / 6;
        }
    }

        .footer__nav-list {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            column-gap: 40px;
            row-gap: 15px;

            @include breakpoint(tablet) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                column-gap: 30px;
                row-gap: 10px;
            }

            a {
                @include mediumText;
                @include font(13px, 13px);

                color: $c-black;
                text-decoration: none;
                white-space: nowrap;

                @include breakpoint(tablet) {
                    @include font(12px, 12px);
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

    .footer__advice {
        margin-top: 40px;

        @include breakpoint(tablet) {
            grid-row: 2 / 3;
            text-align: center;
            margin: 30px 0 35px;
        }
    }

        .footer__advice-text {
            @include apply-map($f-title);
            @include mediumText;
            @include font(18px, 24px);

            color: $f-title-color;
            margin-bottom: 10px;
        }

    .footer__awards {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;

        @include breakpoint(tablet) {
            grid-row: 7 / 8;
            gap: 15px;
            margin-top: 30px;
            margin-bottom: 60px;
        }

        li {
            max-width: 120px;
            max-height: 100px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .footer__socials {
        text-align: right;

        @include breakpoint(tablet) {
            grid-row: 6 / 7;
            text-align: center;
            margin-top: 20px;
        }

        h2 {
            @include apply-map($f-title);
            @include font(20px, 34px);

            color: $c-black;
            letter-spacing: 0.6px;
            margin-bottom: 5px;
        }
    }

        .footer__socials-list {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-bottom: 30px;

            @include breakpoint(tablet) {
                justify-content: center;
                margin-bottom: 0;
            }

            svg {
                fill: var(--theme-colour);
                transition: fill 150ms ease-in;
            }

            a:hover svg {
                fill: $c-highlight;
            }
        }

        .footer__credit-wrap {
            @include font(12px, 18px);

            @include breakpoint(tablet) {
                position: absolute;
                bottom: 20px;
                left: var(--site-gutter-mobile);
                right: var(--site-gutter-mobile);
                text-align: center;
            }

            a {
                color: $c-black;
            }
        }
