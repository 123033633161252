.hero {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.hero--full {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
   
    body:has(.alert-bar) & {
        height: calc((var(--vh, 1vh) * 100) - var(--alert-bar-height));
    }

    .body--debug & {
        height: calc((var(--vh, 1vh) * 100) - 20px);
    }

    .body--admin & {
        height: calc((var(--vh, 1vh) * 100) - 44px);
    }

    .body--debug.body--admin & {
        height: calc((var(--vh, 1vh) * 100) - 20px - 44px);
    }
}

.hero--offer {
    aspect-ratio: 2 / 1;
    max-height: calc(100vh - var(--brochure-height) - var(--alert-bar-height)); 

    @include breakpoint(tablet) {
        aspect-ratio: 8 / 5;
    }
}

.hero--tall {
    aspect-ratio: 9 / 4;
    max-height: calc(100vh - var(--brochure-height) - var(--alert-bar-height)); 

    @include breakpoint(tablet) {
        aspect-ratio: 8 / 5;
    }
}

.hero--medium {
    aspect-ratio: 3 / 1;

    @include breakpoint(tablet) {
        aspect-ratio: 8 / 5;
    }
}

.hero--short {
    aspect-ratio: 6 / 1;

    @include breakpoint(tablet) {
        aspect-ratio: 8 / 5;
    }
}

.hero--has-itinerary {
    // Stop the hero shrinking in the space do avoid itinerary summary
    // overflowing and cutting off the navigation
    @media (max-height: 750px) and (min-width: 999px) {
        max-height: none;
    }
}

    .hero__image {
        z-index: -1;
        height: 100%;
        position: relative;
        filter: brightness(0.65);

        @include breakpoint(tablet) {
            width: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .hero__video {
        filter: brightness(0.65);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(var(--video-scale, 1));
        width: 100%;
        height: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .hero__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .hero--short & {
            justify-content: flex-end;
            padding-bottom: 60px;
        }

        .hero--offer &,
        .hero--has-itinerary & {
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0 calc(((100vw - var(--narrow-width)) / 2) + var(--site-gutter));
        }

        .hero--has-itinerary:not(.hero--offer) & {
            padding-bottom: 70px;

            @include breakpoint(nav) {
                padding-bottom: 0;
            }
        }
    }

        .hero__subtitle {
            @include subtitle;

            color: $c-white;
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                @include font(12px, 16px);

                margin-bottom: 5px;
            }
        }

        .hero__title {
            @include large-title;

            max-width: 600px;
            margin: 0 auto;
            color: $c-white;
            text-align: center;

            @include breakpoint(phablet) {
                max-width: 300px;
            }

            .hero--short & {
                max-width: none;
            }
        }

        .hero__cta {
            margin-top: 40px;

            @include breakpoint(tablet) {
                margin-top: 25px;
            }
        }

        .hero__button {
            margin-top: 40px;
        }

    .hero__scroll {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        width: 62px;
        height: 62px;

        @include breakpoint(tablet) {
            bottom: 60px;
        }

        @media (max-height: 750px) and (min-width: 999px) {
            bottom: 30px;
        }

        svg {
            fill: $c-white;
            transition: transform 100ms ease-in;

            &:hover {
                fill: $c-highlight;
            }
        }
    }
