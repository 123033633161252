@font-face {
    font-family: 'EB Garamond';
    src: url('/fonts/EBGaramond/EBGaramond-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src: url('/fonts/EBGaramond/EBGaramond-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter/Inter-Variable.woff2') format('woff2-variations');
    src: url('/fonts/Inter/Inter-Variable.woff2') format('woff2') tech('variations');
    font-weight: 100 900;
    font-display: swap;
}

