@mixin styled-underline($color: $c-highlight, $alignment: 'left', $layout: null, $width: 40px) {
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 22px;

    @if ($layout == 'desktop-only') {
        @include breakpoint(tablet) {
            margin: 0;
            padding: 0;
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: $width;
        height: 5px;
        background-color: $color;

        @if ($alignment == 'center') {
            left: 50%;
            transform: translateX(-50%);
        }

        @if ($layout == 'desktop-only') {
            @include breakpoint(tablet) {
                display: none;
            }
        }
    }
}
