.award-border {
    width: 190px;
    height: 178px;
    fill: none;
}

.checkbox {
    width: 16px;
    height: 16px;
}

.duration_white {
    width: 17px;
    height: 19px;
}

.icon-anchor {
    width: 45px;
    height: 45px;
}

.icon-arrow-down {
    width: 10px;
    height: 7px;
}

.icon-arrow-left {
    width: 10px;
    height: 14px;
    fill: none;
}

.icon-arrow-right {
    width: 10px;
    height: 14px;
    fill: none;
}

.icon-calendar {
    width: 45px;
    height: 45px;
}

.icon-camera {
    width: 45px;
    height: 45px;
}

.icon-circle-arrow {
    width: 63px;
    height: 63px;
}

.icon-close {
    width: 13px;
    height: 13px;
}

.icon-facebook {
    width: 27px;
    height: 27px;
}

.icon-filters {
    width: 18px;
    height: 18px;
    fill: none;
}

.icon-globe {
    width: 45px;
    height: 45px;
}

.icon-holiday {
    width: 21px;
    height: 24px;
}

.icon-instagram {
    width: 27px;
    height: 27px;
}

.icon-investors {
    width: 123px;
    height: 37px;
}

.icon-logo-adventure-colour {
    width: 213px;
    height: 92px;
    fill: none;
}

.icon-logo-adventure-white {
    width: 166px;
    height: 71px;
    fill: none;
}

.icon-logo-colour {
    width: 112px;
    height: 48px;
    fill: none;
}

.icon-logo-white {
    width: 168px;
    height: 71px;
    fill: none;
}

.icon-map-pin {
    width: 52px;
    height: 76px;
}

.icon-message {
    width: 25px;
    height: 25px;
    fill: none;
}

.icon-moon {
    width: 45px;
    height: 45px;
}

.icon-pencil {
    width: 45px;
    height: 45px;
}

.icon-phone {
    width: 17px;
    height: 17px;
}

.icon-plane {
    width: 45px;
    height: 45px;
}

.icon-play {
    width: 70px;
    height: 71px;
    fill: none;
}

.icon-quote-left {
    width: 120px;
    height: 120px;
    fill: none;
}

.icon-quote-right {
    width: 120px;
    height: 120px;
    fill: none;
}

.icon-sail {
    width: 45px;
    height: 45px;
}

.icon-ship {
    width: 42px;
    height: 42px;
    fill: none;
}

.icon-twitter {
    width: 28px;
    height: 27px;
}

.icon-youtube {
    width: 27px;
    height: 27px;
}

.logo {
    width: 164px;
    height: 72px;
}

.menu {
    width: 14px;
    height: 10px;
}

.minus {
    width: 24px;
    height: 24px;
    fill: #462674;
}

.nights_white {
    width: 17px;
    height: 19px;
}

.plus {
    width: 24px;
    height: 24px;
    fill: #462674;
}

.ports_white {
    width: 23px;
    height: 23px;
}

.ship_white {
    width: 19px;
    height: 23px;
}

