.field {
    margin-bottom: 24px;

    @include breakpoint(tablet) {
        margin-bottom: 15px;
    }

    &#contact_optin_wrap {
        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }   
    }

    input.check_box {
        appearance: none;
        border: 1px solid $c-border-grey;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $c-white;
        transition: outline .2s ease;
        outline-offset: -1px;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;

        &:disabled {
            background-color: var(--c-field-disabled);
        }

        &:checked {
            border-color: $c-black;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }

    label.check_box {
        display: inline-flex;
        gap: 9px;
        align-items: center;

        .check_box__text {
            @include form-label;
        }
    }

    input.radio {
        appearance: none;
        border: 1px solid var(--theme-colour);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: $c-white;
        transition: outline .2s ease;
        vertical-align: middle;
        outline-offset: -1px;
        outline-style: solid;
        outline-width: 2px;
        outline-color: transparent;

        &:disabled {
            background-color: var(--c-field-disabled);
        }

        &:checked {
            border-color: var(--theme-colour);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 18px 18px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3ccircle cx='10' cy='10' fill='%2342297d' r='5'/%3e%3c/svg%3e");
        }
    }

    label.radio {
        display: inline-flex;
        gap: 8px;
        align-items: flex-start;

        .radio__text {
            @include form-label;
        }
    }
}

.field--center {
    display: flex;

    label.check_box,
    label.radio {
        align-items: center;
    }
}

.field--flush {
    margin: 0;
}

.field--responsive {
    margin-bottom: 7px;

    @include breakpoint(tablet) {
        margin: 0;
    }
}

    .field__label {
        display: block;
        @include form-label;
        margin-bottom: 9px;
        span {
            color: $c-error;
        }
    }


    .field__describe {
        display: block;
        @include apply-map($f-primary);
        @include rem(11px);
        margin-bottom: 10px;
        margin-top: -5px;
    }

    .field__optional {
        @include apply-map($f-primary);
        &:before {
            content: "(";
        }
        &:after {
            content: ")";
        }
    }

input.input,
select.input,
textarea.input {
    margin: 0;
    width: 100%;
    border: 1px solid $c-border-grey;
    transition: box-shadow .3s;
    -webkit-appearance: none;
    border-radius: 2px;
    border: 1px solid $c-border-grey;
    padding: 12px 15px;
    display: block;
    transition: outline .2s ease;
    outline-style: solid;
    outline-width: 2px;
    outline-offset: -1px;
    outline-color: transparent;
    background-color: $c-white;

    @include breakpoint(tablet) {
        @include font(16px, 20px);
    }

    .filters__filter & {
      border: none;
      max-width: 175px;
      padding-left: 0;
      height: 38px;
      min-height: 38px;

      @include breakpoint(tablet) {
          max-width: 150px;
      }
    }
}

input.input,
select.input {
    height: 45px;
    padding: 4px 15px;
}
textarea.input {
    padding: 8px 15px;
    height: 160px;
    max-width: 100%;
}

select.input {
    cursor: pointer;
    max-width: 100%;
    appearance: none;
    padding-right: 40px;
    min-height: 45px;
    background-position: right 20px center;
    background-size: 10px 7px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7'%3E%3Cpath d='M4.86 5.024 8.906.169a.466.466 0 0 1 .717.594L5.258 6a.59.59 0 0 1-.093.105.517.517 0 0 1-.254.105h-.08a.443.443 0 0 1-.34-.186L.106.763A.469.469 0 0 1 .167.107a.474.474 0 0 1 .656.056l4.044 4.854-.006.007Z'/%3E%3C/svg%3E%0A");
    text-overflow: ellipsis;
}

.field-inline {
    font-size: 0;
}

.field.error,
.field-error {
    .input,
    .select-replace__value,
    select {
        border-color: $c-error;
    }
    p.error {
        color: $c-error;
        margin-top: 5px;
        @include rem(14px);
    }
}

.js {
    .antispam-hide {
        display: none;
    }
    .field--antispam {
        display: none;
    }
}
