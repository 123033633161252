.band {
    padding: 60px 0;

    @include breakpoint(tablet) {
        padding: 30px 0;
    }
}

.band--grey {
    background: $c-background;
}

.band--unresponsive {
    @include breakpoint(tablet) {
        padding: 60px 0;
    }
}

.band--tall {
    padding: 90px 0;

    @include breakpoint(tablet) {
        padding: 50px 0;
    }
}

.band--med {
    padding: 35px 0;
}

.band--med-bottom {
    padding-bottom: 35px;

    @include breakpoint(tablet) {
        padding-bottom: 10px;
    }
}

.band--flush {
    padding: 0;
}

.band--flush-top {
    padding-top: 0;
}

.band--m-top {
    @include breakpoint(tablet) {
        padding-top: 30px;
    }
}

.band--m-med-top {
    @include breakpoint(tablet) {
        padding-top: 15px;
    }
}

.band--m-flush {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

.band--flush-bottom {
    padding-bottom: 0;
}

.band--m-flush-bottom {
    @include breakpoint(tablet) {
        padding-bottom: 0;
    }
}

.band--m-flush-top {
    @include breakpoint(tablet) {
        padding-top: 0;
    }
}

.band--scroll-margin-top {
    scroll-margin-top: var(--header-height);

    @include breakpoint(tablet) {
        scroll-margin-top: var(--header-height-mobile);
    }
}

.band--relative {
    position: relative;
    z-index: 1;
}

.band--overflow-hidden {
    overflow: hidden;
}
