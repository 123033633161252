.overlay--extra-wide.overlay--extra-wide {
    .overlay__container {
        @include breakpoint(tablet) {
            max-width: calc(100vw - (2 * var(--site-gutter-mobile)));    
        }
    }

    .overlay__content {
        max-height: 80vh;
        overflow-y: auto;
    }

    .overlay__close {
        width: 45px;
        height: 45px;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
            stroke: $c-black;
        }
    }
}
