body.has-brochure {
    &:has(.header[data-position="sticky"]) .newsletter-popup {
        display: none; // override fade-in
    }

    .newsletter-popup {
        display: none;
    } 
} 

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.newsletter-popup {
    position: fixed;
    left: var(--site-gutter);
    color: $c-white;
    bottom: 20px;
    padding: 15px 24px;
    background-color: var(--theme-colour);
    border-radius: 40px;
    z-index: 10;

    @include breakpoint(tablet) {
        left: var(--site-gutter-mobile);
        display: none;
        opacity: 0;
        animation: fade-in 300ms ease-in;

        body:has(.header[data-position="sticky"]) & {
            display: block;
            animation: fade-in 300ms ease-in;
            opacity: 1;
        }
    }
}

    .newsletter-popup__close {
        display: none;
        position: absolute;
        top: -5px;
        right: -5px;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        place-items: center;
        background-color: $c-white;
        cursor: pointer;

        svg {
            width: 10px;
            height: 10px;
        }

        .newsletter-popup--active & {
            display: grid;
        }
    }

    .newsletter-popup__label {
        @include mediumText;

        display: flex;
        align-items: center;
        gap: 10px;
        color: $c-white;
        cursor: pointer;
        transition: color 150ms ease-in;

        &:hover {
            color: $c-highlight;
        }
    }

    .newsletter-popup__form {
        overflow: hidden;
        max-height: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        transition: all 250ms ease-in;

        .newsletter-popup--active & {
            max-height: 100px;
            margin-top: 15px;
        }
    }

        .newsletter-popup__input {
            color: $c-white;
            border: none;
            padding: 0;
            padding-bottom: 5px;
            border-bottom: 1px solid $c-white;
            background-color: transparent;
    
            &::placeholder {
                color: $c-white;
                opacity: 1;
            }

            &:focus-visible {
                outline: none;
            }
        }
