.page-intro {
    text-align: center;

    & > *:last-child {
        margin-bottom: 0;
    }
}

    .page-intro__subtitle {
        @include subtitle;

        margin-bottom: 10px;

        @include breakpoint(tablet) {
            margin-bottom: 5px;
        }
    }

    .page-intro__title {
        @include small-title--alt;

        margin-bottom: 30px;
    }

    .page-intro__title--underline {
        @include styled-underline($alignment: 'center', $width: 70px);
    }

    .page-intro__title--h1 {
        @include medium-title;
        color: $c-black;
    }

    .page-intro__intro {
        @include intro;

        margin-bottom: 20px;
    }

    .page-intro__body {
        margin-bottom: 20px;
    }

    .page-intro__body--m-top {
        margin-top: 30px;

        @include breakpoint(tablet) {
            margin-top: 15px;
        }
    }
