html {
    font-size: 100%;
    overflow-y: scroll;
    height: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}  

body {
    @include font(15px, 24px);
    @include apply-map($f-primary);

    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $c-white;
    color: $f-primary-color;
    min-width: 320px;

    @include breakpoint(tablet) {
        @include font(14px, 24px);
    }

    &.has-brochure .outer-wrapper {
        padding-bottom: 80px;
    }
}

.body--onetrust {
    padding-top: 75px;
    transition: padding-top 0.3s ease-out;

    @media (max-width: 1401px) {
        padding-top: 80px;
    }

    @media (max-width: 848px) {
        padding-top: 105px;
    }

    @media (max-width: 684px) {
        padding-top: 135px;
    }

    @media (max-width: 565px) {
        padding-top: 160px;
    }

    @media (max-width: 400px) {
        padding-top: 185px;
    }
}

html #debug-bar {
    width: 100%;
    z-index: 30;
    height: 20px;
    top: 0 !important;

    @include breakpoint(tablet) {
        display: none !important;
    }
}

html #debug-holder {
    @include breakpoint(tablet) {
        display: none !important;
    }
}

.body--debug.body--admin {
    #debug-bar {
        top: 45px !important;
    }
}

#admin-bar a {
    line-height: 1;
}
