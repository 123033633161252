.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: var(--site-max-width);
    padding: 0 var(--site-gutter);

    @include breakpoint(tablet) {
        padding: 0 var(--site-gutter-mobile);
        max-width: var(--site-tab-max-width);
    }
}

.wrap--narrow {
    max-width: var(--narrow-width);

    @include breakpoint(tablet) {
        max-width: var(--site-tab-max-width);
    }
}

.wrap--v-narrow {
    max-width: 980px;

    @include breakpoint(tablet) {
        max-width: var(--site-tab-max-width);
    }
}

.wrap--extra-narrow {
    max-width: var(--very-narrow-width);

    @include breakpoint(tablet) {
        max-width: var(--site-tab-max-width);
    }
}

.wrap--relative {
    position: relative;
}

.wrap--center {
    text-align: center;
}
