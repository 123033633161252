.newsletter-signup {}

    .newsletter-signup__active {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        column-gap: 20px;

        &[hidden] {
            display: none;
        }
    }

    .newsletter-signup__label {
        @include small-title--alt;

        color: $c-black;
        margin-bottom: 20px;
        grid-column: span 2;
    }

    .newsletter-signup__input {
        border: none;
        padding-bottom: 5px;
        border-bottom: 1px solid $c-light-grey;

        &::placeholder {
            color: $c-light-grey;
            opacity: 1;
        }
    }

    .newsletter-signup__success {
        display: none;
    }

    .newsletter-signup__success--active {
        @include small-title--alt;

        display: block;
    }
