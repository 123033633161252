.alert-bar {
    background-color: var(--notice-colour, $c-black);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: $c-white;

    svg {
        margin-left: 5px;
        fill: $c-highlight;
    }
}

    .alert-bar__text {
        @include subtitle;
        @include semiBoldText;
        @include font(12px, 16px);

        text-decoration: none;
    }

    p.alert-bar__text {
        color: $c-white;

        @include breakpoint(tablet) {
            display: none;
        }
    }
