$cookie-fontcolor: $c-black;
$cookie-altfontcolor: $c-black;
$cookie-fontsize: 14px;
$cookie-fontweight: 400;
$cookie-bg: $c-white;
$cookie-radius: 999px;
$cookie-switch-bg: #FFF;
$cookie-switch-fg: #222;
$cookie-btn-texttransform: none;
$cookie-btn-letterspacing: 0;
$cookie-btn-fontsize: 15px;
$cookie-btn-fontweight: 500;
$cookie-btn-onhover: #000;
$cookie-btn-onhover-bg: #fff;
$cookie-wrappadding: 15px 20px;
$cookie-wrappadding-mobile: 15px 20px;
$cookie-banner-maxwidth: 1200px;
$cookie-copy-fontsize: 15px;
$cookie-lineheight: 25px;

@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-onetrust";

#onetrust-consent-sdk {
    order: -1;
}

.ot-fade-in {
    animation: none !important;
}

#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
.ot-pc-refuse-all-handler,
#ot-sdk-btn,
#ot-sdk-btn.ot-sdk-show-settings {
    background: #FFF !important;
    color: #000 !important;
    font-family: 'Inter', Helvetica, sans-serif;
    transition: background 100ms ease-in, color 100ms ease-in !important;
    border: 2px solid #000 !important;

    &:hover {
        background: #000 !important;
        color: #FFF !important;
        border-color: #000 !important;
    }
}

#onetrust-pc-btn-handler {
    font-size: 12px !important;
    color: #000 !important;
    border: 0 !important;
    position: relative !important;
    padding: 0 !important;
    padding-bottom: 3px !important;
    text-transform: uppercase !important;
    transition: font-weight 250ms ease-in;
    letter-spacing: 1px !important;

    &:hover {
        font-weight: 500 !important;
        color: #000 !important;
        opacity: 1 !important;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: #000;
        width: 25%;
        transition: width 250ms linear;
    }

    &:hover::after {
        width: 100%;
    }
}

.otFlat .ot-sdk-row {
    margin: 0 auto !important;
}

#ot-sdk-cookie-policy .ot-sdk-container {
    @include breakpoint(phablet) {
        padding: 0 !important;
    }
}

.otFlat #onetrust-button-group-parent {
    padding-right: 0 !important;
}

#onetrust-banner-sdk.otFlat {
    position: relative !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    max-height: 100% !important;

    @include breakpoint(tablet) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.otFlat .ot-sdk-row {
    max-width: none !important;

    #onetrust-policy-text {
        font-size: 13px !important;
        color: #000 !important;
        font-weight: 400 !important;
    }
}

#ot-pc-title,
#cookie-policy-title {
    color: $c-black !important;
    font-size: 30px !important;
    line-height: 1.1333 !important;
    font-family: 'EB Garamond', 'Times new roman', serif !important;
    letter-spacing: 0.5px !important;
    margin-bottom: 20px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;

    @include breakpoint(tablet) {
        font-size: 25px !important;
        line-height: 28px !important;
    }
}

#onetrust-pc-sdk #ot-pc-title {
    margin-bottom: 0 !important;
}

h4.ot-sdk-cookie-policy-group,
th.ot-table-header,
#ot-pvcy-hdr,
#ot-pvcy-txt,
h3[id^=ot-header-id-],
.ot-cat-header {
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: #42297d !important;
    letter-spacing: 1.5px !important;
    font-weight: 500 !important;
}

#ot-sdk-cookie-policy .ot-sdk-container {
    width: 100% !important;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-pc-title,
#ot-pc-desc,
.ot-grp-desc {
    color: $c-black !important;
}

#cookie-policy-description,
.ot-sdk-cookie-policy-group-desc {
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
}

#cookie-policy-description {
    margin-bottom: 2em !important;
}
