@keyframes show-mobile-menu {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hide-mobile-menu {
    from {
        display: flex;
        opacity: 1;
    }
    to {
        display: none;
        opacity: 0;
    }
}

.header {
    --header-background-colour: transparent;
    --header-content-colour: #{$c-white};
    --header-content-inverse: #{$c-black};
    --header-padding: 40px;
    position: sticky;
    top: -16px; // We need this as otherwise height reflow breaks intersection observer
    padding: 16px var(--header-padding) 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: background-color 200ms ease-in;
    z-index: 5; // think about this
    background-color: var(--header-background-colour);

    @include breakpoint(nav) {
        justify-content: space-between;
        padding: 11px var(--site-gutter-mobile);
        top: 0;
        z-index: 20;
    }

    &:not(.header--plain) + #inner-wrapper {
        margin-top: calc(var(--header-height) * -1);

        @include breakpoint(nav) {
            margin-top: calc(var(--header-height-mobile) * -1);
        }
    }

    &.header--plain,
    &[data-position="sticky"],
    &.header--mobile-menu-active {
        --header-content-inverse: #{$c-white};
        --header-background-colour: #{$c-white};
        --header-content-colour: #{$c-black};
    }
}

    .header__logo {
        position: relative;

        svg {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity 200ms ease-in;
            width: 118px;
            height: 50px;
        }
        
        svg.icon-logo-white,
        svg.icon-logo-adventure-white {
            width: 168px;
            height: 71px;

            @include breakpoint(nav) {
                width: 118px;
                height: 50px;
            }
        }

        svg.icon-logo-colour,
        svg.icon-logo-adventure-colour {
            opacity: 0;
        }

        [data-position="sticky"] &,
        .header--mobile-menu-active &,
        .header--plain & {
            svg.icon-logo-white,
            svg.icon-logo-adventure-white {
                opacity: 0;
            }

            svg.icon-logo-colour,
            svg.icon-logo-adventure-colour {
                opacity: 1;
            }
        }
    }

    .header__links {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: auto;

        @include breakpoint(nav) {
            display: none;
            opacity: 0;
            animation: hide-mobile-menu 300ms;

            .header--mobile-menu-active & {
                flex-direction: column;
                display: flex;
                opacity: 1;
                align-items: stretch;
                position: absolute;
                top: var(--header-height-mobile);
                left: 0;
                gap: 0;
                width: 100%;
                height: calc(100dvh - var(--header-height-mobile) - var(--alert-bar-height));
                animation: show-mobile-menu 350ms;
                background-color: var(--header-background-colour);
                padding: 0 var(--site-gutter-mobile);
            }

            [data-position="sticky"].header--mobile-menu-active & {
                height: calc(100dvh - var(--header-height-mobile));
            }
        }
    }

        .header__item {
            display: flex;
            align-items: center;
            gap: 5px;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            transition: border 200ms ease-in;
            position: relative;

            @media (hover: hover) and (pointer: fine) {
                &:hover,
                &.header__item--active {
                    border-bottom: 5px solid $c-highlight;

                    @include breakpoint(nav) {
                        border-bottom: 1px solid $c-highlight;
                    }
                }
            }

            @include breakpoint(nav) {
                position: static;
                border-bottom: 1px solid $c-border-grey;
                border-top: 1px solid transparent;
            }

            svg {
                fill: var(--header-content-colour);
                transition: fill 200ms ease-in, transform 200ms ease-in;
                margin-bottom: 1px;
            }
        }

        .header__item--active svg {
            transform: rotate(180deg);

            @include breakpoint(nav) {
                transform: rotate(90deg);
            }
        }

        .header__item--cruiseline {
            position: static; // reset position so subheader can go full widths
        }

            .header__link {
                @include font(13px, 18px);
                @include mediumText;

                color: var(--header-content-colour);
                letter-spacing: 1.3px;
                padding: 18px 0;
                text-transform: uppercase;
                text-decoration: none;
                transition: color 200ms ease-in;
                cursor: pointer;

                @include breakpoint(nav) {
                    display: flex;
                    padding: 15px 0;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                .header__item--active & {
                    @include breakpoint(nav) {
                        top: 0;
                        left: var(--site-gutter-mobile);
                        right: var(--site-gutter-mobile);
                        padding: 10px 0;
                        background-color: $c-white;
                        position: absolute;
                        height: 50px;
                        box-sizing: border-box;
                        z-index: 5;
                        width: auto;
                        flex-direction: row-reverse;
                        justify-content: flex-end;
                        gap: 10px;
                        border-bottom: 1px solid $c-border-grey;

                        svg {
                            transform: rotate(90deg);
                        }
                    }
                }
            }

            .header__mobile-text {
                display: none;
                
                @include breakpoint(nav) {
                    @include apply-map($f-title);
                    @include font(25px, 34px);

                    display: block;
                    margin: 35px 0 25px;
                }
            }

    .header__toggle {
        display: none;

        @include breakpoint(nav) {
            position: relative;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            position: relative;
    
            &::after,
            &::before {
                content: '';
                position: absolute;
                height: 3px;
                right: 0;
                border-radius: 2px;
                background-color: var(--header-content-colour);
                transition: transform 200ms ease;
            }
    
            &::after {
                top: 7px;
                width: 38px;
    
                .header--mobile-menu-active & {
                    left: 50%;
                    transform: translate(-50%, 11px) rotate(45deg);
                    width: 29px;
                }
            }
    
            &::before {
                bottom: 7px;
                width: 22px;
    
                .header--mobile-menu-active & {
                    left: 50%;
                    transform: translate(-50%, -12px) rotate(-45deg);
                    width: 29px;
                }
            }
        }
    }
              
            .header__toggle-line {
                @include breakpoint(nav) {
                    width: 30px;
                    height: 3px;
                    border-radius: 2px;
                    background-color: var(--header-content-colour);
    
                    .header--mobile-menu-active & {
                        display: none;
                    }
                }
            }
